@import "variables";

@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  src: url("./react/assets/fonts/Montserrat-Regular.woff")
}

@font-face {
  font-family: 'Montserrat Medium';
  font-weight: 400;
  src: url("./react/assets/fonts/Montserrat-SemiBold.woff")
}

@font-face {
  font-family: 'Montserrat Bold';
  font-weight: 400;
  src: url("./react/assets/fonts/Montserrat-Bold.woff")
}

@font-face {
  font-family: 'Baskerville Libre';
  font-weight: 400;
  src: url("./react/assets/fonts/LibreBaskerville-Regular.woff")
}

@font-face {
  font-family: 'Baskerville Libre Bold';
  font-weight: 400;
  src: url("./react/assets/fonts/LibreBaskerville-Bold.woff")
}



body, html {
  height: 100%;
  font-family: "Montserrat", serif;
}

a {
  display: block;
}

 #root  {
  height: 100%;
}

.ml-2 {
  margin-left: 1.5rem!important;
}

.mr-2 {
  margin-right: 1rem;
}

.m-0 {
  margin: 0!important;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.flex {
  display: flex;
}

.w-100 {
  width: 100%!important;
}

.no-cursor {
  cursor: initial;
}

.crn-br-desktop {
  display: block;
}

.overflow-hidden {
  overflow: hidden!important;
}

.text-center {
  text-align: center!important;
}

.page-container {
  width: 100%;
  max-width: 1160px;
  margin: 0 auto;
  padding: 0 14px;
  position: relative;

  &--title {
    margin-top: 80px;
    font-style: normal;
    font-weight: bold;
    font-size: 44px;
    line-height: 55px;
    text-align: center;
    font-family: "Baskerville Libre", serif;
    margin-bottom: 0;
    color: #000000;

    @media screen and (max-width: $md) {
      text-align: center;
      font-size: 30px;
      line-height: 37px;
      margin-top: 30px;
  }

  }

  &_last {
    margin-bottom: 40px;
  }
}

.error-message {
  font-size: 12px;
  color: red;
}

.success-message {
  margin-top: 1rem;
  font-size: 12px;
  color: #4BB543;
  text-align: center;
}


@media screen and (max-width: 1440px) {
  .page-container {
    max-width: 1140px;
  }
}

@media screen and (max-width: $md) {
  .crn-br-desktop {
    display: none;
  }
}
