@import "../../../variables";

.footer {
  background: #183C67;
  padding: 40px 0;

  &--logo, &--realtor-icon {
    margin-top: 30px;
  }

  &--realtor-icon {
    width: 100px;
    display: block;
    margin: 10px auto;
  }

  &--all-rights-reserved {
    margin-top: 40px;
    font-style: normal;
    font-size: 12px;
    line-height: 15px;
    opacity: .5;
    color: white;
  }

  &--text {
    font-weight: normal;
    font-size: 14px;
    font-weight: normal;
    line-height: 200%;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 0;
    cursor: pointer;
  }

  &--grey {
    opacity: .5;
    margin-bottom: 1rem;
  }

  &--all-rights-reserved-mobile {
    display: none!important;
  }
}


@media screen and (max-width: $md) {
  .footer {

    padding-bottom: 0;

    text-align: center;

    &--logo {
      margin-top: 0;
      margin-bottom: 43px;
    }

    &--all-rights-reserved {
      margin-top: 43px;
      display: none;
    }

    &--line-mobile {
      line-height: 150%;
    }

    &--email {
      display: block;
      text-transform: none;
    }

    &--grey {
      margin-top: 24px;
      margin-bottom: 0;
    }

    &--all-rights-reserved-mobile {
      display: block!important;
      margin-top: 56px;
      padding-bottom: 8px;
      margin-bottom: 0;

      p {
        opacity: .5;
        color: white;
        margin-bottom: 0;
      }
    }
  }
}
